.buttonLink {
  color:#4b4b4b
}
.buttonLink :hover{
  color: #862633;
}
.policyTable .ant-table-content {
  border-radius: 10px;
}
.policyTable .ant-table-thead .ant-table-cell {
  background: #A29463;
}
.policyTable .ant-table-tbody > tr:hover > td {
  background: #DEE2E6;
}
.ant-input{
  font-size: 16px !important;
}

* {
  font-family: "Gotham-Light";
  /* font-weight: bold; */
}

/* div {
  font-family: "Gotham-Bold";
} */
.common_title > div{
  font-weight: bold;
  font-size: 20px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 16px solid #888888;
}
.missionDetailArrowDown {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 10px solid #888888;
}
.checkmark20 {
    display:inline-block;
    width: 18px;
    height:18px;
    /* background: #c9ff00; */
    /* border-radius:50%; */
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    /* border:solid 1px; */
}
.checkmark20:before{
    content:"";
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:7px;
    top:4px;
    /* border:solid 1px; */
}
.checkmark20:after{
    content:"";
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:4px;
    top:10px;
    /* border:solid 1px; */
}





.policy_document_title {
  background-color: #eee6cd;
  font-weight: bold;
  border: unset;
  padding: 8px 15px;
  /* margin: 10px 0px; */
  border-radius: 10px;
  transform:translate(-15px,0px);
  margin: 0;
}

.policy_profile_display > div {
  font-weight:bold;
  font-size: 20px;
  /* font-family: "Gotham-Bold" ;  */
}
.policy_detail_display_breadcrumb > div {
  font-weight:bold;
  font-size: 15px;
}

.policy_download_btn {
  /* background-color: #C8CFD6; */
  border: unset;
  padding:8px 16px;
  border-radius:8px;
  width: 136px;
}


.buttonStyle1{
    background: #C9BE9C;
    border-color: #C9BE9C;
    /* border:'solid 0px', */
    color:#000000;
}

.buttonStyle1:hover {
    background: #DCD0AA;
    border-color: #DCD0AA;
    color:#000000;
}
.buttonStyle1:focus {
    background: #C9BE9C;
    border-color: #C9BE9C;
    color:#000000;
}

.buttonStyle2{
    background: #AC4548;
    border-color: #AC4548;
    /* border:'solid 0px', */
    color:#FFFFFF;
}

.buttonStyle2:hover {
    background: #CD5255;
    border-color: #CD5255;
    color:#FFFFFF;
}
.buttonStyle2:focus {
    background: #AC4548;
    border-color: #AC4548;
    color:#FFFFFF;
}

.colCenter{
  /* border: solid 1px; */
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
.titleText{
  color:#000000;
  font-weight: bold;
  font-size: 20px;
}
.descText{
  color:#888888;
  font-size: 16px;
}
.dashboardSectionContainer {
  margin: 0 auto;
  padding: 2% 15% 2% 15%;
}

.dashboardSectionText  {
  font-size:26px;
  font-weight:bold;
  color:#666666;
}

.dashboard_section_div{
  transition : 0.3s ease-in-out;
}
.dashboard_section_div:hover{

}
@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Light.otf');
}
@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold.otf');
}

div.bear-react-carousel__slide-item__div {
  background-position: 0 35% !important;
}

.bear-react-carousel__root {
  padding-top: 30.00% !important;
}






.policyTable ::-webkit-scrollbar {
  height: 10px;
}

.policyTable ::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */  
  background-color: #0000000a;
  border-radius: 0px;
}

/* Handle */
.policyTable ::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 10px;
}

/* Handle on hover */
.policyTable ::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}


/* ImageSlider */
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-size: cover; */
  background-size:100% 100%;
  height: 1000px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.ant-modal-content{
  border-radius: 10px;
}

.missionTitleLineTxt{
  font-size:30px;
  color:#707070;
}
.missionTitleLineContainerDiv{
  margin: 0 auto;
  padding: 2% 15% 2% 15%;
}

.missionObjsContainer{
  margin: 0 auto;
  padding: 0% 15% 0% 15%;
}

.missionObjContainerDiv{
  margin-bottom:40px;
  padding: 0% 0px 0% 0px;
  border: solid 1px #707070;
  border-radius:45px;
  box-shadow: 3px 3px 6px #00000011;
}
.missionObjTitle{
  color:#707070;
  font-size: 30px;
  line-height: 140%;
}
.missionObjDesc{
  color:#888888;
  font-size: 22px;
}
.missionObjPoints{
  color:#707070;
  font-weight: bold;
  font-size: 30px;
  font-family: "Gotham-Bold";
}
.missionObjIconK{
  width:60px;
  height:60px;
  border-radius:30px;
  border:solid 1px #fff;
  background-color:#ffffff;
}
.missionObjRedeemBTN{
  min-width:160px;
  /* min-height:80px; */
  border-radius:20px;
  border: solid 1px #707070;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:24px;
  color:#707070;
  cursor: pointer;
  padding:10px 10px;
}
/* .missionObjPointsContainer{
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content:flex-end;
  align-items:flex-end;
  padding:0px 20px 10px 0px;
} */

.mission-progress-title {
  font-size: 42px;
  color:#918E7F;
  font-weight: bold;
  margin:0px 18px;
  text-shadow: 0px 1.5px 3px #0000002a;
}
.mission-progress-dot {
  width:23px;
  height:23px;
  border-radius:11.5px;
  background-color: #707070;
  box-shadow: 0px 1.5px 3px 1px #0000002a;
  margin:10px;
}
.miss-progress-item-container{
  display:flex ;
  min-height: 86px;
  /* margin:10px 30px 10px 30px; */
  margin:10px 0px 10px 0px;
  width: 100%;
  /* border: solid 1px; */
}
.mission-my-referral-code-div{
  min-width:170px;
  /* min-height:80px; */
  border-radius:16px;
  align-self: center;
  text-align: center;
  margin: 0px 0px;
  border: solid 1px #707070;
  display:flex;
  justify-content:center;
  align-items: center;
  font-size:24px;
  color:#707070;
  cursor:pointer;
  padding:8px 10px;
}
.mission-friend-progress-container-div{
  width:80%;
}
.mission-friend-progress-checkbox-outter-div{
  border:solid 2px #3F3D56;
  width:64px;
  height:64px;
  border-radius:32px;
  background-color:#ffffff;
  display:flex;
  justify-content:center;
  align-items:center;
}
.mission-friend-progress-checkbox-inner-div{
  width:44px;
  height:44px;
  border-radius:22px;
}
.mission-friend-progress-checkbox-inner-tick{
  width:44px;
  height:44px;
}

.mission-referral-code-frame-container{
  border:solid 1px #888;
  border-radius:34px;
  text-align:center;
  font-size:20px;
  height:116px;
  min-width:80%;
  padding:0px 20px 0px 20px;
  margin-top:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}

.mission-referral-code-copy-btn{
  cursor: pointer;
  min-width:108px;
  height:48px;
  border-radius:13px;
  background-color:#707070;
  display:flex;
  justify-content: center;
  align-items:center;
  color:#ffffff;
  font-size:22px;
  align-self:center;
  margin:0px 0px 0px 10px;
  padding:0px 10px;
}

@media screen and (max-width: 992px) {
  .missionObjTitle{
    font-size: 26px;
  }
  .missionObjRedeemBTN{
    min-width:140px;
    /* min-height:70px; */
    border-radius:20px;
    font-size:22px;
  }
}
@media screen and (max-width: 768px) {
  .mission-friend-progress-container-div{
    width:90%;
  }
  .missionObjContainerDiv{
    margin-bottom:35px;
    border-radius:40px;
  }

  .mission-progress-title {
    font-size: 36px;
    margin:0px 14px;
  }

  .missionTitleLineTxt{
    font-size:24px;
  }
  .mission-my-referral-code-div{
    min-width:160px;
    /* min-height:74px; */
    border-radius:14px;
    font-size:19px;
  }
  .missionObjTitle{
    font-size: 24px;
  }
  .missionObjDesc{
    font-size: 20px;
  }
  .missionObjPoints{
    font-size: 24px;
  }
  .missionObjIconK{
    width:50px;
    height:50px;
    border-radius:25px;
  }
  .missionObjRedeemBTN{
    min-width:120px;
    /* min-height:70px; */
    border-radius:18px;
    font-size:20px;
    padding:8px 8px;
  }
}

@media screen and (max-width: 576px) {
  .mission-progress-title {
    font-size: 32px;
    margin:0px 10px;
  }
  .mission-progress-dot {
    width:18px;
    height:18px;
    border-radius:9px;
  }
  .missionObjContainerDiv{
    margin-bottom:30px;
    border-radius:35px;
  }
  .missionTitleLineTxt{
    font-size:20px;
  }
  .missionTitleLineContainerDiv{
    padding: 2% 5% 2% 5%;
  }
  .mission-my-referral-code-div{
    min-width:160px;
    /* min-height:74px; */
    border-radius:10px;
    font-size:16px;
  }

  .mission-friend-progress-container-div{
    width:100%;
  }

  .mission-friend-progress-checkbox-outter-div{
    width:48px;
    height:48px;
    border-radius:24px;
  }
  .mission-friend-progress-checkbox-inner-div{
    width:32px;
    height:32px;
    border-radius:16px;
  }
  .mission-friend-progress-checkbox-inner-tick{
    width:32px;
    height:32px;
  }

  .missionObjsContainer{
    margin: 0 auto;
    padding: 0% 5% 0% 5%;
  }
  .missionObjTitle{
    font-size: 22px;
  }
  .missionObjDesc{
    font-size: 17px;
  }
  .missionObjPoints{
    font-size: 20px;
  }
  .missionObjIconK{
    width:40px;
    height:40px;
    border-radius:20px;
  }
  .missionObjRedeemBTN{
    min-width:100px;
    /* min-height:68px; */
    border-radius:16px;
    font-size:18px;
    padding:6px 6px;
  }
}

@media screen and (max-width: 480px) {
  .dashboardSectionContainer {
    padding: 2% 10% 2% 10%;
  }

  .policy_profile_display > div {
    font-size: 14px;
  }

  .policy_document_title {
    padding: 5px 10px;
    border-radius: 6px;
    transform:translate(-10px,0px);
    margin: 0;
  }
  .policyTable .ant-table-thead > tr > th {
    padding: 10px;
  }
  .policy_download_btn {
    padding:8px 8px;
    border-radius:8px;
    width: 102px;
  }
  .ant-card-body {
    padding: 15px;
  }

  .common_title > div {
    font-size: 14px;
  }

  .titleText{
    font-size: 14px;
  }
  .descText{
    font-size: 12px;
  }

  .arrow-down {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 10px solid #888888;
  }

  .contact-info {
    padding: 3%;
    font-size: 12px;
    text-align: left;
  }

  .missionTitleLineTxt{
    font-size:20px;
  }
  .mission-progress-title {
    font-size: 28px;
    margin:0px 8px;
  }
  .mission-progress-dot {
    width:16px;
    height:16px;
    border-radius:8px;
  }
  .mission-my-referral-code-div{
    min-width:150px;
    /* min-height:70px; */
    border-radius:10px;
    font-size:15px;
    padding: 8px 5px;
  }

  .mission-friend-progress-checkbox-outter-div{
    width:36px;
    height:36px;
    border-radius:18px;
  }
  .mission-friend-progress-checkbox-inner-div{
    width:24px;
    height:24px;
    border-radius:12px;
  }
  .mission-friend-progress-checkbox-inner-tick{
    width:24px;
    height:24px;
  }

  .missionObjContainerDiv{
    margin-bottom:20px;
    border-radius:30px;
  }

  .missionObjTitle{
    font-size: 20px;
  }
  .missionObjDesc{
    font-size: 14px;
  }
  .missionObjPoints{
    font-size: 16px;
  }
  .missionObjIconK{
    width:30px;
    height:30px;
    border-radius:15px;
  }
  .missionObjRedeemBTN{
    min-width: 66px;
    /* min-height:64px; */
    border-radius:12px;
    font-size:16px;
    padding: 5px 10px;
  }

  .missionDetailArrowDown {
    width: 0;
    height: 0;
    border-left: 4.8px solid transparent;
    border-right: 4.8px solid transparent;

    border-top: 8px solid #888888;
  }

  .mission-referral-code-frame-container{
    font-size:15px;
    min-width:90%;
    padding:0px 15px 0px 15px;
    margin-top:20px;
  }
  .mission-referral-code-copy-btn{
    min-width:94px;
    height:42px;
    border-radius:10px;
    font-size:18px;
  }
}

@media screen and (max-width: 300px) {
  .mission-my-referral-code-div{
    min-width:140px;
    /* min-height:70px; */
    border-radius:10px;
    font-size:14px;
    padding: 8px 5px;
  }
  .missionObjTitle{
    font-size: 16px;
  }
  .missionObjRedeemBTN{
    min-width: 60px;
    /* min-height:64px; */
    border-radius:10px;
    font-size:14px;
    padding: 4px 8px;
  }
  .missionDetailArrowDown {
    width: 0;
    height: 0;
    border-left: 3.6px solid transparent;
    border-right: 3.6px solid transparent;

    border-top: 6px solid #888888;
  }
}
